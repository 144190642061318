
/**
 * @roxi/routify 2.18.18
 * File generated Fri Dec 13 2024 11:03:21 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.18"
export const __timestamp = "2024-12-13T11:03:21.223Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes.js"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_admin__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/admin/components/src/LigneCompteUtilisateur",
                  "id": "_admin_components_src_LigneCompteUtilisateur",
                  "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_components_src_LigneCompteUtilisateur)
                }
              ],
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/components/src"
            }
          ],
          "meta": {
            "$$bundleId": "_admin__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/admin/components"
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "title": "Planning AMSB - Administration",
            "guard": "admin"
          },
          "meta": {
            "title": "Planning AMSB - Administration",
            "guard": "admin",
            "$$bundleId": "_admin__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/admin/index",
          "id": "_admin_index",
          "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_index)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_admin__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/admin",
      "id": "_admin__layout",
      "component": () => import('./_admin__layout_svelte.js').then(m => m._admin__layout)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "redirect": "/bois/rdvs"
          },
          "meta": {
            "redirect": "/bois/rdvs",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bois/index",
          "id": "_bois_index",
          "component": () => import('../src/pages/bois/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/DispatchModal",
                      "id": "_bois_rdvs_components_src_DispatchModal",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_DispatchModal)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/ExtractionRegistre",
                      "id": "_bois_rdvs_components_src_ExtractionRegistre",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_ExtractionRegistre)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/FilterBanner",
                      "id": "_bois_rdvs_components_src_FilterBanner",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_FilterBanner)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/LigneDate",
                      "id": "_bois_rdvs_components_src_LigneDate",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_LigneDate)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/LigneDateAttente",
                      "id": "_bois_rdvs_components_src_LigneDateAttente",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_LigneDateAttente)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/LigneRdv",
                      "id": "_bois_rdvs_components_src_LigneRdv",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_LigneRdv)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/LigneRdvAttente",
                      "id": "_bois_rdvs_components_src_LigneRdvAttente",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_LigneRdvAttente)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/Placeholder",
                      "id": "_bois_rdvs_components_src_Placeholder",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_Placeholder)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/rdvs/components/src/ThirdPartyAddress",
                      "id": "_bois_rdvs_components_src_ThirdPartyAddress",
                      "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_components_src_ThirdPartyAddress)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_bois_rdvs__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/bois/rdvs/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_bois_rdvs__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/bois/rdvs/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Bois",
                "guard": "bois"
              },
              "meta": {
                "title": "Planning AMSB - Bois",
                "guard": "bois",
                "$$bundleId": "_bois_rdvs__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/bois/rdvs/index",
              "id": "_bois_rdvs_index",
              "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs_index)
            },
            {
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - RDV bois",
                "param-is-page": true,
                "guard": "bois/edit"
              },
              "meta": {
                "title": "Planning AMSB - RDV bois",
                "param-is-page": true,
                "guard": "bois/edit",
                "$$bundleId": "_bois_rdvs__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/bois/rdvs/:id",
              "id": "_bois_rdvs__id",
              "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs__id)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_bois_rdvs__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bois/rdvs",
          "id": "_bois_rdvs__layout",
          "component": () => import('./_bois_rdvs__layout_svelte.js').then(m => m._bois_rdvs__layout)
        },
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_bois_stats__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/bois/stats/components/src/FilterBanner",
                      "id": "_bois_stats_components_src_FilterBanner",
                      "component": () => import('./_bois_stats__layout_svelte.js').then(m => m._bois_stats_components_src_FilterBanner)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_bois_stats__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/bois/stats/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_bois_stats__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/bois/stats/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Statistiques bois",
                "guard": "bois"
              },
              "meta": {
                "title": "Planning AMSB - Statistiques bois",
                "guard": "bois",
                "$$bundleId": "_bois_stats__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/bois/stats/index",
              "id": "_bois_stats_index",
              "component": () => import('./_bois_stats__layout_svelte.js').then(m => m._bois_stats_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_bois_stats__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bois/stats",
          "id": "_bois_stats__layout",
          "component": () => import('./_bois_stats__layout_svelte.js').then(m => m._bois_stats__layout)
        }
      ],
      "path": "/bois"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_chartering_charters__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/chartering/charters/components/src/FilterBanner",
                      "id": "_chartering_charters_components_src_FilterBanner",
                      "component": () => import('./_chartering_charters__layout_svelte.js').then(m => m._chartering_charters_components_src_FilterBanner)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_chartering_charters__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/chartering/charters/components/src/LigneCharter",
                      "id": "_chartering_charters_components_src_LigneCharter",
                      "component": () => import('./_chartering_charters__layout_svelte.js').then(m => m._chartering_charters_components_src_LigneCharter)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_chartering_charters__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/chartering/charters/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_chartering_charters__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/chartering/charters/components"
            },
            {
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Affrètement maritime",
                "param-is-page": true,
                "guard": "chartering/edit"
              },
              "meta": {
                "title": "Planning AMSB - Affrètement maritime",
                "param-is-page": true,
                "guard": "chartering/edit",
                "$$bundleId": "_chartering_charters__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/chartering/charters/:id",
              "id": "_chartering_charters__id",
              "component": () => import('./_chartering_charters__layout_svelte.js').then(m => m._chartering_charters__id)
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Affrètement maritime",
                "query-params-is-page": true,
                "guard": "chartering"
              },
              "meta": {
                "title": "Planning AMSB - Affrètement maritime",
                "query-params-is-page": true,
                "guard": "chartering",
                "$$bundleId": "_chartering_charters__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/chartering/charters/index",
              "id": "_chartering_charters_index",
              "component": () => import('./_chartering_charters__layout_svelte.js').then(m => m._chartering_charters_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_chartering_charters__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/chartering/charters",
          "id": "_chartering_charters__layout",
          "component": () => import('./_chartering_charters__layout_svelte.js').then(m => m._chartering_charters__layout)
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "redirect": "/chartering/charters"
          },
          "meta": {
            "redirect": "/chartering/charters",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/chartering/index",
          "id": "_chartering_index",
          "component": () => import('../src/pages/chartering/index.svelte').then(m => m.default)
        }
      ],
      "path": "/chartering"
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/agence/Agence",
                      "id": "_config_components_src_agence_Agence",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_agence_Agence)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/agence/Service",
                      "id": "_config_components_src_agence_Service",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_agence_Service)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/agence"
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/ajouts-rapides/AjoutsRapides",
                      "id": "_config_components_src_ajoutsRapides_AjoutsRapides",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_ajoutsRapides_AjoutsRapides)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/ajouts-rapides/LigneAjoutRapideBois",
                      "id": "_config_components_src_ajoutsRapides_LigneAjoutRapideBois",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_ajoutsRapides_LigneAjoutRapideBois)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/ajouts-rapides"
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/bandeau-info/BandeauInfo",
                      "id": "_config_components_src_bandeauInfo_BandeauInfo",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_bandeauInfo_BandeauInfo)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/bandeau-info/LigneConfigBandeauInfo",
                      "id": "_config_components_src_bandeauInfo_LigneConfigBandeauInfo",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_bandeauInfo_LigneConfigBandeauInfo)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/bandeau-info"
                },
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/ConfigLine",
                  "id": "_config_components_src_ConfigLine",
                  "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_ConfigLine)
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/cotes/Cotes",
                      "id": "_config_components_src_cotes_Cotes",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_cotes_Cotes)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/cotes/LigneCote",
                      "id": "_config_components_src_cotes_LigneCote",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_cotes_LigneCote)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/cotes"
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/marees/LigneMarees",
                      "id": "_config_components_src_marees_LigneMarees",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_marees_LigneMarees)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/marees/Marees",
                      "id": "_config_components_src_marees_Marees",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_marees_Marees)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/marees"
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/pdf/ConfigsPDF",
                      "id": "_config_components_src_pdf_ConfigsPDF",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_pdf_ConfigsPDF)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_config__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/config/components/src/pdf/LigneConfigPDF",
                      "id": "_config_components_src_pdf_LigneConfigPDF",
                      "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_pdf_LigneConfigPDF)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/pdf"
                },
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/TitreSection",
                  "id": "_config_components_src_TitreSection",
                  "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_TitreSection)
                },
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_config__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/config/components/src/TitreSousSection",
                  "id": "_config_components_src_TitreSousSection",
                  "component": () => import('./_config__layout_svelte.js').then(m => m._config_components_src_TitreSousSection)
                }
              ],
              "meta": {
                "$$bundleId": "_config__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/config/components/src"
            }
          ],
          "meta": {
            "$$bundleId": "_config__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/config/components"
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "title": "Planning AMSB - Configuration",
            "guard": "config"
          },
          "meta": {
            "title": "Planning AMSB - Configuration",
            "guard": "config",
            "$$bundleId": "_config__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/config/index",
          "id": "_config_index",
          "component": () => import('./_config__layout_svelte.js').then(m => m._config_index)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_config__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/config",
      "id": "_config__layout",
      "component": () => import('./_config__layout_svelte.js').then(m => m._config__layout)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_consignation_escales__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/consignation/escales/components/src/LigneEscale",
                      "id": "_consignation_escales_components_src_LigneEscale",
                      "component": () => import('./_consignation_escales__layout_svelte.js').then(m => m._consignation_escales_components_src_LigneEscale)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_consignation_escales__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/consignation/escales/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_consignation_escales__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/consignation/escales/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Consignation",
                "query-params-is-page": true,
                "guard": "consignation"
              },
              "meta": {
                "title": "Planning AMSB - Consignation",
                "query-params-is-page": true,
                "guard": "consignation",
                "$$bundleId": "_consignation_escales__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/consignation/escales/index",
              "id": "_consignation_escales_index",
              "component": () => import('./_consignation_escales__layout_svelte.js').then(m => m._consignation_escales_index)
            },
            {
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Escale consignation",
                "param-is-page": true,
                "guard": "consignation/edit"
              },
              "meta": {
                "title": "Planning AMSB - Escale consignation",
                "param-is-page": true,
                "guard": "consignation/edit",
                "$$bundleId": "_consignation_escales__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/consignation/escales/:id",
              "id": "_consignation_escales__id",
              "component": () => import('./_consignation_escales__layout_svelte.js').then(m => m._consignation_escales__id)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_consignation_escales__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/consignation/escales",
          "id": "_consignation_escales__layout",
          "component": () => import('./_consignation_escales__layout_svelte.js').then(m => m._consignation_escales__layout)
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "redirect": "/consignation/escales"
          },
          "meta": {
            "redirect": "/consignation/escales",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/consignation/index",
          "id": "_consignation_index",
          "component": () => import('../src/pages/consignation/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_consignation_stats__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/consignation/stats/components/src/CarteEscale",
                      "id": "_consignation_stats_components_src_CarteEscale",
                      "component": () => import('./_consignation_stats__layout_svelte.js').then(m => m._consignation_stats_components_src_CarteEscale)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_consignation_stats__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/consignation/stats/components/src/FilterBanner",
                      "id": "_consignation_stats_components_src_FilterBanner",
                      "component": () => import('./_consignation_stats__layout_svelte.js').then(m => m._consignation_stats_components_src_FilterBanner)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_consignation_stats__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/consignation/stats/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_consignation_stats__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/consignation/stats/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Statistiques consignation",
                "guard": "consignation"
              },
              "meta": {
                "title": "Planning AMSB - Statistiques consignation",
                "guard": "consignation",
                "$$bundleId": "_consignation_stats__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/consignation/stats/index",
              "id": "_consignation_stats_index",
              "component": () => import('./_consignation_stats__layout_svelte.js').then(m => m._consignation_stats_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_consignation_stats__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/consignation/stats",
          "id": "_consignation_stats__layout",
          "component": () => import('./_consignation_stats__layout_svelte.js').then(m => m._consignation_stats__layout)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Tirants d'eau",
                "guard": "consignation"
              },
              "meta": {
                "title": "Planning AMSB - Tirants d'eau",
                "guard": "consignation",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/consignation/te/index",
              "id": "_consignation_te_index",
              "component": () => import('../src/pages/consignation/te/index.svelte').then(m => m.default)
            }
          ],
          "path": "/consignation/te"
        },
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_consignation_tv__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/consignation/tv/components/src/LigneEscale",
                      "id": "_consignation_tv_components_src_LigneEscale",
                      "component": () => import('./_consignation_tv__layout_svelte.js').then(m => m._consignation_tv_components_src_LigneEscale)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_consignation_tv__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/consignation/tv/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_consignation_tv__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/consignation/tv/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Consignation"
              },
              "meta": {
                "title": "Planning AMSB - Consignation",
                "$$bundleId": "_consignation_tv__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/consignation/tv/index",
              "id": "_consignation_tv_index",
              "component": () => import('./_consignation_tv__layout_svelte.js').then(m => m._consignation_tv_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "reset": true,
            "bundle": true
          },
          "meta": {
            "reset": true,
            "bundle": true,
            "$$bundleId": "_consignation_tv__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/consignation/tv",
          "id": "_consignation_tv__layout",
          "component": () => import('./_consignation_tv__layout_svelte.js').then(m => m._consignation_tv__layout)
        }
      ],
      "path": "/consignation"
    },
    {
      "isIndex": true,
      "isPage": true,
      "ownMeta": {
        "redirect": "/login"
      },
      "meta": {
        "redirect": "/login",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_login__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/login/components/src/FirstLogin",
                  "id": "_login_components_src_FirstLogin",
                  "component": () => import('./_login__layout_svelte.js').then(m => m._login_components_src_FirstLogin)
                },
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_login__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/login/components/src/LoginForm",
                  "id": "_login_components_src_LoginForm",
                  "component": () => import('./_login__layout_svelte.js').then(m => m._login_components_src_LoginForm)
                },
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_login__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/login/components/src/LoginMenu",
                  "id": "_login_components_src_LoginMenu",
                  "component": () => import('./_login__layout_svelte.js').then(m => m._login_components_src_LoginMenu)
                }
              ],
              "meta": {
                "$$bundleId": "_login__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/login/components/src"
            }
          ],
          "meta": {
            "$$bundleId": "_login__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/login/components"
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "title": "Planning AMSB - Connexion"
          },
          "meta": {
            "title": "Planning AMSB - Connexion",
            "$$bundleId": "_login__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/login/index",
          "id": "_login_index",
          "component": () => import('./_login__layout_svelte.js').then(m => m._login_index)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "reset": true,
        "bundle": true
      },
      "meta": {
        "reset": true,
        "bundle": true,
        "$$bundleId": "_login__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/login",
      "id": "_login__layout",
      "component": () => import('./_login__layout_svelte.js').then(m => m._login__layout)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_manutention_activitesHorsNavires__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/manutention/activites-hors-navires/components/src/FilterBanner",
                      "id": "_manutention_activitesHorsNavires_components_src_FilterBanner",
                      "component": () => import('./_manutention_activitesHorsNavires__layout_svelte.js').then(m => m._manutention_activitesHorsNavires_components_src_FilterBanner)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_manutention_activitesHorsNavires__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/manutention/activites-hors-navires/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_manutention_activitesHorsNavires__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/manutention/activites-hors-navires/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Activités hors navires",
                "guard": "manutention"
              },
              "meta": {
                "title": "Planning AMSB - Activités hors navires",
                "guard": "manutention",
                "$$bundleId": "_manutention_activitesHorsNavires__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/manutention/activites-hors-navires/index",
              "id": "_manutention_activitesHorsNavires_index",
              "component": () => import('./_manutention_activitesHorsNavires__layout_svelte.js').then(m => m._manutention_activitesHorsNavires_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_manutention_activitesHorsNavires__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/manutention/activites-hors-navires",
          "id": "_manutention_activitesHorsNavires__layout",
          "component": () => import('./_manutention_activitesHorsNavires__layout_svelte.js').then(m => m._manutention_activitesHorsNavires__layout)
        },
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_manutention_equipements__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/manutention/equipements/components/src/EquipmentCard",
                      "id": "_manutention_equipements_components_src_EquipmentCard",
                      "component": () => import('./_manutention_equipements__layout_svelte.js').then(m => m._manutention_equipements_components_src_EquipmentCard)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_manutention_equipements__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/manutention/equipements/components/src/EquipmentDrawer",
                      "id": "_manutention_equipements_components_src_EquipmentDrawer",
                      "component": () => import('./_manutention_equipements__layout_svelte.js').then(m => m._manutention_equipements_components_src_EquipmentDrawer)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_manutention_equipements__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/manutention/equipements/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_manutention_equipements__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/manutention/equipements/components"
            },
            {
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Personnel de manutention",
                "guard": "manutention/edit"
              },
              "meta": {
                "title": "Planning AMSB - Personnel de manutention",
                "guard": "manutention/edit",
                "$$bundleId": "_manutention_equipements__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/manutention/equipements/:id",
              "id": "_manutention_equipements__id",
              "component": () => import('./_manutention_equipements__layout_svelte.js').then(m => m._manutention_equipements__id)
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Personnel de manutention",
                "guard": "manutention"
              },
              "meta": {
                "title": "Planning AMSB - Personnel de manutention",
                "guard": "manutention",
                "$$bundleId": "_manutention_equipements__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/manutention/equipements/index",
              "id": "_manutention_equipements_index",
              "component": () => import('./_manutention_equipements__layout_svelte.js').then(m => m._manutention_equipements_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_manutention_equipements__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/manutention/equipements",
          "id": "_manutention_equipements__layout",
          "component": () => import('./_manutention_equipements__layout_svelte.js').then(m => m._manutention_equipements__layout)
        }
      ],
      "path": "/manutention"
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_personnel__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/personnel/components/src/StaffCard",
                  "id": "_personnel_components_src_StaffCard",
                  "component": () => import('./_personnel__layout_svelte.js').then(m => m._personnel_components_src_StaffCard)
                },
                {
                  "isPage": true,
                  "meta": {
                    "$$bundleId": "_personnel__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/personnel/components/src/StaffDrawer",
                  "id": "_personnel_components_src_StaffDrawer",
                  "component": () => import('./_personnel__layout_svelte.js').then(m => m._personnel_components_src_StaffDrawer)
                }
              ],
              "meta": {
                "$$bundleId": "_personnel__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/personnel/components/src"
            }
          ],
          "meta": {
            "$$bundleId": "_personnel__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/personnel/components"
        },
        {
          "isPage": true,
          "ownMeta": {
            "title": "Planning AMSB - Personnel de manutention",
            "guard": "personnel"
          },
          "meta": {
            "title": "Planning AMSB - Personnel de manutention",
            "guard": "personnel",
            "$$bundleId": "_personnel__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/personnel/:id",
          "id": "_personnel__id",
          "component": () => import('./_personnel__layout_svelte.js').then(m => m._personnel__id)
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "title": "Planning AMSB - Personnel de manutention",
            "guard": "personnel"
          },
          "meta": {
            "title": "Planning AMSB - Personnel de manutention",
            "guard": "personnel",
            "$$bundleId": "_personnel__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/personnel/index",
          "id": "_personnel_index",
          "component": () => import('./_personnel__layout_svelte.js').then(m => m._personnel_index)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_personnel__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/personnel",
      "id": "_personnel__layout",
      "component": () => import('./_personnel__layout_svelte.js').then(m => m._personnel__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/tests/index",
          "id": "_tests_index",
          "component": () => import('../src/pages/tests/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/tests/inputDecimal",
          "id": "_tests_inputDecimal",
          "component": () => import('../src/pages/tests/inputDecimal.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/tests/svelecte",
          "id": "_tests_svelecte",
          "component": () => import('../src/pages/tests/svelecte.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "isReset": true,
      "path": "/tests",
      "id": "_tests__reset",
      "component": () => import('../src/pages/tests/_reset.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "title": "Planning AMSB - Tiers",
            "guard": "tiers"
          },
          "meta": {
            "title": "Planning AMSB - Tiers",
            "guard": "tiers",
            "$$bundleId": "_tiers__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/tiers/index",
          "id": "_tiers_index",
          "component": () => import('./_tiers__layout_svelte.js').then(m => m._tiers_index)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_tiers__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/tiers",
      "id": "_tiers__layout",
      "component": () => import('./_tiers__layout_svelte.js').then(m => m._tiers__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "title": "Planning AMSB - Utilisateur"
          },
          "meta": {
            "title": "Planning AMSB - Utilisateur",
            "$$bundleId": "_user__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/user/index",
          "id": "_user_index",
          "component": () => import('./_user__layout_svelte.js').then(m => m._user_index)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_user__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/user",
      "id": "_user__layout",
      "component": () => import('./_user__layout_svelte.js').then(m => m._user__layout)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_vrac_dispatch__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/vrac/dispatch/components/src/FilterBanner",
                      "id": "_vrac_dispatch_components_src_FilterBanner",
                      "component": () => import('./_vrac_dispatch__layout_svelte.js').then(m => m._vrac_dispatch_components_src_FilterBanner)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_vrac_dispatch__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/vrac/dispatch/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_vrac_dispatch__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/dispatch/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Dispatch vrac",
                "guard": "vrac"
              },
              "meta": {
                "title": "Planning AMSB - Dispatch vrac",
                "guard": "vrac",
                "$$bundleId": "_vrac_dispatch__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/dispatch/index",
              "id": "_vrac_dispatch_index",
              "component": () => import('./_vrac_dispatch__layout_svelte.js').then(m => m._vrac_dispatch_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_vrac_dispatch__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/vrac/dispatch",
          "id": "_vrac_dispatch__layout",
          "component": () => import('./_vrac_dispatch__layout_svelte.js').then(m => m._vrac_dispatch__layout)
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "redirect": "/vrac/rdvs"
          },
          "meta": {
            "redirect": "/vrac/rdvs",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/vrac/index",
          "id": "_vrac_index",
          "component": () => import('../src/pages/vrac/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_vrac_produits__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/vrac/produits/components/src/LigneQualite",
                      "id": "_vrac_produits_components_src_LigneQualite",
                      "component": () => import('./_vrac_produits__layout_svelte.js').then(m => m._vrac_produits_components_src_LigneQualite)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_vrac_produits__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/vrac/produits/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_vrac_produits__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/produits/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Produits vrac",
                "guard": "vrac/edit"
              },
              "meta": {
                "title": "Planning AMSB - Produits vrac",
                "guard": "vrac/edit",
                "$$bundleId": "_vrac_produits__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/produits/index",
              "id": "_vrac_produits_index",
              "component": () => import('./_vrac_produits__layout_svelte.js').then(m => m._vrac_produits_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_vrac_produits__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/vrac/produits",
          "id": "_vrac_produits__layout",
          "component": () => import('./_vrac_produits__layout_svelte.js').then(m => m._vrac_produits__layout)
        },
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_vrac_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/vrac/rdvs/components/src/DispatchModal",
                      "id": "_vrac_rdvs_components_src_DispatchModal",
                      "component": () => import('./_vrac_rdvs__layout_svelte.js').then(m => m._vrac_rdvs_components_src_DispatchModal)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_vrac_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/vrac/rdvs/components/src/LigneDate",
                      "id": "_vrac_rdvs_components_src_LigneDate",
                      "component": () => import('./_vrac_rdvs__layout_svelte.js').then(m => m._vrac_rdvs_components_src_LigneDate)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_vrac_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/vrac/rdvs/components/src/LigneRdv",
                      "id": "_vrac_rdvs_components_src_LigneRdv",
                      "component": () => import('./_vrac_rdvs__layout_svelte.js').then(m => m._vrac_rdvs_components_src_LigneRdv)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_vrac_rdvs__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/vrac/rdvs/components/src/Placeholder",
                      "id": "_vrac_rdvs_components_src_Placeholder",
                      "component": () => import('./_vrac_rdvs__layout_svelte.js').then(m => m._vrac_rdvs_components_src_Placeholder)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_vrac_rdvs__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/vrac/rdvs/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_vrac_rdvs__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/rdvs/components"
            },
            {
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - RDV vrac",
                "param-is-page": true,
                "guard": "vrac/edit"
              },
              "meta": {
                "title": "Planning AMSB - RDV vrac",
                "param-is-page": true,
                "guard": "vrac/edit",
                "$$bundleId": "_vrac_rdvs__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/rdvs/:id",
              "id": "_vrac_rdvs__id",
              "component": () => import('./_vrac_rdvs__layout_svelte.js').then(m => m._vrac_rdvs__id)
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Vrac",
                "query-params-is-page": true,
                "guard": "vrac"
              },
              "meta": {
                "title": "Planning AMSB - Vrac",
                "query-params-is-page": true,
                "guard": "vrac",
                "$$bundleId": "_vrac_rdvs__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/rdvs/index",
              "id": "_vrac_rdvs_index",
              "component": () => import('./_vrac_rdvs__layout_svelte.js').then(m => m._vrac_rdvs_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "bundle": true
          },
          "meta": {
            "bundle": true,
            "$$bundleId": "_vrac_rdvs__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/vrac/rdvs",
          "id": "_vrac_rdvs__layout",
          "component": () => import('./_vrac_rdvs__layout_svelte.js').then(m => m._vrac_rdvs__layout)
        },
        {
          "isDir": true,
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_vrac_tv__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/vrac/tv/components/src/LigneDate",
                      "id": "_vrac_tv_components_src_LigneDate",
                      "component": () => import('./_vrac_tv__layout_svelte.js').then(m => m._vrac_tv_components_src_LigneDate)
                    },
                    {
                      "isPage": true,
                      "meta": {
                        "$$bundleId": "_vrac_tv__layout_svelte.js",
                        "recursive": true,
                        "preload": false,
                        "prerender": true
                      },
                      "path": "/vrac/tv/components/src/LigneRdv",
                      "id": "_vrac_tv_components_src_LigneRdv",
                      "component": () => import('./_vrac_tv__layout_svelte.js').then(m => m._vrac_tv_components_src_LigneRdv)
                    }
                  ],
                  "meta": {
                    "$$bundleId": "_vrac_tv__layout_svelte.js",
                    "recursive": true,
                    "preload": false,
                    "prerender": true
                  },
                  "path": "/vrac/tv/components/src"
                }
              ],
              "meta": {
                "$$bundleId": "_vrac_tv__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/tv/components"
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "title": "Planning AMSB - Vrac"
              },
              "meta": {
                "title": "Planning AMSB - Vrac",
                "$$bundleId": "_vrac_tv__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/vrac/tv/index",
              "id": "_vrac_tv_index",
              "component": () => import('./_vrac_tv__layout_svelte.js').then(m => m._vrac_tv_index)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "reset": true,
            "bundle": true
          },
          "meta": {
            "reset": true,
            "bundle": true,
            "$$bundleId": "_vrac_tv__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/vrac/tv",
          "id": "_vrac_tv__layout",
          "component": () => import('./_vrac_tv__layout_svelte.js').then(m => m._vrac_tv__layout)
        }
      ],
      "path": "/vrac"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

